import { workItem } from "@fscrypto/domain";
import { WorkItem, WorkItemNew, WorkItemType, WorkItemUpdate } from "@fscrypto/domain/work-item";
import { Client, HttpClient } from "@fscrypto/http";
import { $path } from "remix-routes";
import { z } from "zod";

export class WorkItemClient {
  constructor(private readonly client: HttpClient) {}
  async get(parentId?: string | null, workItemData?: WorkItem): Promise<WorkItem[]> {
    const result = await this.client.get<{ items: WorkItem[] }>(
      `/api/work-items${parentId ? `?parentId=${parentId}` : ""}`,
      workItem.schemas,
    );
    // we need to create a dummy table for each query workItem
    if (workItemData?.typename === "query") return [createResultsTable(parentId), ...result.items];
    else return result.items;
  }

  async getByIdAndType(id: string, typename?: WorkItemType): Promise<WorkItem> {
    const query = typename ? { id, type: typename } : undefined;
    const result = await this.client.get($path("/api/work-items/:id", { id }, query), workItem.schema);
    return result as WorkItem;
  }

  async delete({ id, typename }: WorkItem) {
    const result = await this.client.post<{ items: WorkItem[] }>($path("/api/work-items/delete"), {
      id,
      typename,
    });
    return result.items;
  }

  async move({ id, typename, parentId }: WorkItem): Promise<WorkItem> {
    const result = await this.client.post<{ workItem: WorkItem }>($path("/api/work-items/move"), {
      id,
      parentId,
      typename,
    });
    return result.workItem;
  }

  async moveMany(parentId: string | null | undefined, workItems: WorkItem[]): Promise<WorkItem[]> {
    const result = await this.client.post<{ items: WorkItem[] }>(
      $path("/api/collections/:id/multi-move", { id: parentId ?? "null" }),
      {
        items: workItems.map((item) => ({ id: item.id, type: item.typename })),
      },
    );
    return result.items;
  }

  async fork({ id, typename, parentId }: WorkItem): Promise<WorkItem> {
    const result = await this.client.post<{ workItem: WorkItem }>(
      $path("/api/work-items/fork"),
      {
        id,
        parentId,
        typename,
      },
      z.object({ workItem: workItem.schema }),
    );
    return result.workItem;
  }

  async update(id: string, { typename, ...rest }: WorkItemUpdate): Promise<WorkItem> {
    const result = await this.client.post<{ workItem: WorkItem }>($path("/api/work-items/update"), {
      ...rest,
      id,
      typename,
    });
    return result.workItem;
  }

  async create({ name, parentId, typename }: WorkItemNew): Promise<WorkItem> {
    const result = await this.client.post<{ workItem: WorkItem }>(
      $path("/api/work-items/create"),
      {
        name,
        parentId,
        typename,
      },
      z.object({ workItem: workItem.schema }),
    );
    return result.workItem;
  }

  async filter(filterString: string, type: string = "all"): Promise<WorkItem[]> {
    const result = await this.client.get<{ items: WorkItem[] }>(
      $path("/api/work-items/search", { term: filterString, type }),
    );
    return result.items;
  }

  async getRecent(type: string = "all"): Promise<WorkItem[]> {
    const result = await this.client.get<{ items: WorkItem[] }>($path("/api/work-items/recent", { limit: "10", type }));
    return result.items;
  }
}

export const workItemExplorerClient = new WorkItemClient(new Client());

function createResultsTable(id?: string | null) {
  return {
    id: `results__${id}`,
    name: "Results Table",
    createdAt: new Date(),
    updatedAt: new Date(),
    typename: "table" as WorkItemType,
    parentId: id ?? null,
  } as WorkItem;
}
