import { workItem } from "..";

export type WorkItemUpdate = {
  type: "WORK_ITEM.UPDATED";
  payload: workItem.WorkItem;
};

export type WorkItemMoved = {
  type: "WORK_ITEM.MOVED";
  payload: workItem.WorkItem;
};

export type WorkItemRemoved = {
  type: "WORK_ITEM.REMOVED";
  payload: workItem.WorkItem;
};

export type WorkItemForked = {
  type: "WORK_ITEM.FORKED";
  payload: workItem.WorkItem;
};

export type WorkItemsRemoved = {
  type: "WORK_ITEMS.REMOVED";
  payload: workItem.WorkItem[];
};

export type WorkItemRenamed = {
  type: "WORK_ITEM.RENAMED";
  payload: workItem.WorkItem;
};

export type WorkItemCreated = {
  type: "WORK_ITEM.CREATED";
  payload: workItem.WorkItem;
};

export type WorkItemEvent =
  | WorkItemUpdate
  | WorkItemRemoved
  | WorkItemForked
  | WorkItemsRemoved
  | WorkItemMoved
  | WorkItemRenamed;

export const updated = (payload: workItem.WorkItem) => {
  return {
    type: "WORK_ITEM.UPDATED",
    payload: payload,
  } satisfies WorkItemUpdate;
};

export const removed = (payload: workItem.WorkItem) => {
  return {
    type: "WORK_ITEM.REMOVED",
    payload,
  } satisfies WorkItemRemoved;
};

export const forked = (payload: workItem.WorkItem) => {
  return {
    type: "WORK_ITEM.FORKED",
    payload,
  } satisfies WorkItemForked;
};

export const removedMany = (payload: workItem.WorkItem[]) => {
  return {
    type: "WORK_ITEMS.REMOVED",
    payload,
  } satisfies WorkItemsRemoved;
};

export const renamed = (payload: workItem.WorkItem) => {
  return {
    type: "WORK_ITEM.RENAMED",
    payload,
  } satisfies WorkItemRenamed;
};

export const moved = (payload: workItem.WorkItem) => {
  return {
    type: "WORK_ITEM.MOVED",
    payload,
  } satisfies WorkItemMoved;
};

export const workItems = {
  updated,
  removed,
  forked,
  removedMany,
  renamed,
  moved,
};
